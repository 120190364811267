.links-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.link-item {
    font-size: 12px;
    font-weight: bold;
    color: white;
}