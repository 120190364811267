.company-item {
    display: flex;
    flex-direction: column;
    background-color: #004FC2;
    border-radius: 5px;
    color: white;
    margin: 10px;
    padding: 15px;
    width: 270px;
    height: 220px;
}

.rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rating-text {
    font-size: 18px;
}

.company-star {
    color: #FFD700;
    width: 20px;
    height: 20px;
}

