.newsletter-form {
    margin-top: -10px;
}

.newsletter-input {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.btn-newsletter {
    margin-top: 20px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.form-control {
    width: 300px;
    height: 30px;
    border: solid black 1px;
}

