
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #004FC2;
}

.login-logo {
    margin-top: 5%;
    width: 130px;
    height: 130px;
}

.login-title {
    margin-top: 20px;
    font-size: 35px;
    color: white;
}

.login-input {
    margin-top: 20px;
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 10px;
}

.login-button-wrapper {
    margin-top: 30px;
    margin-bottom: 100%;
}

.login-button {
    width: 250px;
    height: 70px;
    cursor: pointer;
    background-color: #6C107B;
    color: white;
    font-weight: bold;
    font-size: 25px;
    -webkit-box-shadow: 5px 5px 10px 1px #000000;
    box-shadow: 3px 3px 10px 1px #000000;
    border: none;
    border-radius: 15px;
}

.login-button:hover {
    background-color: #540d5f;
  }

  .login-button:active {
    transform: scale(0.98);
    box-shadow: none;
  }

.password-forgot-retour {
    margin-left: -80%;
}

.password-recup-button {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
}