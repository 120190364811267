.register-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #004FC2;
}

.register-title {
    margin-top: 20px;
    font-size: 35px;
    color: white;
}

.register-logo {
    margin-top: 5%;
    width: 130px;
    height: 130px;
}

.register-form-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.register-form-label {
    font-size: 20px;
    color: white;
}

.conditions {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.conditions-text {
    color : white;
}

.register-form-input {
    margin-top: 10px;
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 10px;
}

.register-form-btn {
    margin-top: 20px;
}

.links-wrapper-login {
    margin-top: 20px;
    width: 28%;
    margin-bottom: 8%;
}

.text-danger
{
    color: red;
}