body {
  background-color: #e7e7e7;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #444;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.btn-primary {
  background-color: #6C107B;
  border-color: #6C107B;
  color: #fff;
  border-radius: 15px;
  border: solid 0px;
  box-shadow: rgb(8, 8, 8) 2px 2px 2px 1px;
  width: 150px;
  height: 40px;
  font-weight: bold;
}

.logo {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
}

.btn-cta {
  cursor: pointer;
  background-color: #6C107B;
  color: white;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 80px;
  font-size: 20px;
  -webkit-box-shadow: 5px 5px 10px 1px #000000;
  box-shadow: 3px 3px 10px 1px #000000;
  width: 180px;
  height: 75px;
  border: none;
  border-radius: 15px;
}

.btn-cta:hover {
  background-color: #540d5f;
}

.btn-cta:active {
  transform: scale(0.98);
  box-shadow: none;
}

.btn-primary:hover {
  background-color: #540d5f;
}

.btn-primary:active {
  transform: scale(0.98);
  box-shadow: none;
}
