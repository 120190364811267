.row {
    display: flex;
    justify-content: space-around;
}

.logo {
    width: 50px;
    height: 50px;
}

.footer {
    width: 100%;
    background-color: #004FC2;
    color: white;
    padding: 20px;
}

.footer-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.links-column
{
    margin-top: 15px;
}

.footer-link {
    color: white;
    margin-top: -15px;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
}

.social-media-column {
    margin-top: -15px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.social-media {
    width: 30px;
    height: 30px;
}