.header-wrapper {
    background-color: #004FC2;
    display: flex;
    justify-content: space-between;
}

.header-logo {
    width: 70px;
    height: 70px;
    margin-left: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.header-nav {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.header-link {
    color: white;
    font-weight: bold;
}