.hero {
    display: flex;
    background-color: #004FC2;
    height: 350px;
    width: 90%;
    margin:  15px auto;
}

.hero-content {
    display: flex;
}

.hero-text-part {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 50%;
}

.hero-image-part {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 50%;
}

.hero-title {
    font-size: 22px;
    margin-left: 5%;
    color: white;
    font-weight: bold;
}

.action-wrapper {
    margin-left: 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.hero-promise {
    font-size: 29px;
    color: white;
    margin-top: 10px;
}

.hero-connexion {
    color: white;
    font-size: 13px;
    margin-right: 5%;
}

.hero-image {
    margin-right: 5%;
    width: 430px;
    height: 270px
}

.sigles-list {
    display: flex;
}

.landing-content-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-top: 20px;
}

.landing-content-image {
    width: 80px;
    height: 80px;
}

.landing-content-text {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-main-display {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body-promise-title {
    font-size: 30px;
    color: black;
}

.body-promise {
    width: 50%;
    color: black;
    font-size: 18px;
    display: flex;
    text-align: center;
}

.body-cta {
    background-color: red;
}
